import React, { useEffect, } from "react";
import styles from "./Home.module.css";
import { useNavigate } from "react-router-dom";
import { Sidebar, BottomBar, LoginHelper, Helper, Blogs, ContactUs, } from "../components";
import { Polygon, Logo, } from '../icons';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';


const Home = () => {
  const navigate = useNavigate();
  const isUserLoggedIn = LoginHelper.isUserLoggedIn();
  const { height, width } = Helper.getWindowDimensions();
  const featuredImages = ['/home-fi1-v1.png', '/home-fi2-v1.png', '/home-fi3-v1.png']


  const vibeCheck = (e) => {
    Helper.trackAnalytics('Home', `Click_Check_your_vibe`, `Navigate`);
    navigate('/xpvibes');
  };

  return (
    <div className={styles.homePage}>
      <i className={styles.logo}><Polygon /><i className={styles.xphiveText}><Logo /></i></i>
      <Sidebar analyticsCategory={'Home'} />
      <h1 className={styles.mainHeading}>Community<br/>Travel, Simplified</h1>
      <h4 className={styles.subHeading}>Find your travel squad with XPhive</h4>

      <div className={styles.fiVibeWrap}>
        <Carousel className="fiVibeWrap" preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={20} autoPlay={window.innerWidth > 912} showArrows={false} infiniteLoop={true} stopOnHover={false} showThumbs={false} showStatus={false}>
          {featuredImages.map((fi, i) => {
            return (
              <div key={i} className={styles.fiCarousel} style={{width, height: width - 24}}>
                <img className={styles.image} src={fi} />
              </div>
            );
          })}
        </Carousel>

        <button className={`${!isUserLoggedIn ? styles.fixedVibeCheckBtn : ''}`} onClick={event => vibeCheck(event)}>Match your <b>Vibe</b> now</button>
      </div>

      <div className={styles.howItWorks}>
        <h2 style={{marginTop: !isUserLoggedIn ? '-10px' : ''}}>How it works?</h2>
        <div className={styles.howItWorks1}>
          <div className={styles.imgDiv}><img src="/hiw1-v1.png" /></div>
          <label>Fill a simple image based form and  learn about your travel persona</label>
        </div>
        <div className={styles.howItWorks2}>
          <label>Gain access to  travel experiences and communities basis your vibe</label>
          <div className={styles.imgDiv}><img src="/hiw2.png" /></div>
        </div>
        <div className={styles.howItWorks3}>
          <div className={styles.imgDiv}><img src="/hiw3.png" /></div>
          <label>Create flexible &  adaptive travel  plans matching your vibe with  like-minded explorers</label>
        </div>
      </div>

      <div className={styles.whoAreWe}>
        <h2>Who are we</h2>
        <p>XPhive is a community travel platform for connecting you with your ideal travel companions. We help you discover travel communities which share your interests and style.  Through our platform, you'll experience personalized travel planning alongside like-minded adventurers, ensuring every journey is tailored to your group's preferences. From inception to return, XPhive provides seamless travel coordination and support, allowing you to focus on creating unforgettable memories with your travel tribe. </p>
      </div>

      <div className={styles.blogs}><Blogs analyticsCategory={'Home'} heading={'Mini Blogs'} containerStyle={{marginTop: '12px', paddingLeft: '12px'}} /></div>
      <ContactUs bgColor={'#fff'} />

      {isUserLoggedIn && <BottomBar analyticsCategory={'BottomBar_Home'} />}
    </div>
  );
};

export default Home;
