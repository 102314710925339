const Rotate = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="fi-rr-refresh" clipPath="url(#clip0_1059_1833)">
			<path id="Vector" d="M12.0002 2.00028C13.3267 2.00463 14.6392 2.27201 15.8617 2.78696C17.0842 3.30191 18.1924 4.05419 19.1222 5.00028H16.0002C15.735 5.00028 15.4806 5.10563 15.2931 5.29317C15.1056 5.48071 15.0002 5.73506 15.0002 6.00028C15.0002 6.26549 15.1056 6.51985 15.2931 6.70738C15.4806 6.89492 15.735 7.00028 16.0002 7.00028H20.1432C20.6356 7.00001 21.1078 6.80428 21.456 6.45608C21.8042 6.10788 21.9999 5.6357 22.0002 5.14328V1.00028C22.0002 0.73506 21.8948 0.480706 21.7073 0.293169C21.5198 0.105633 21.2654 0.000275964 21.0002 0.000275964C20.735 0.000275964 20.4806 0.105633 20.2931 0.293169C20.1056 0.480706 20.0002 0.73506 20.0002 1.00028V3.07828C18.3474 1.58973 16.3128 0.590686 14.1243 0.193025C11.9358 -0.204636 9.67983 0.0147879 7.60899 0.826724C5.53815 1.63866 3.73422 3.01105 2.39921 4.7902C1.06421 6.56935 0.250844 8.68502 0.0502025 10.9003C0.0372859 11.0395 0.0535183 11.18 0.0978654 11.3126C0.142213 11.4452 0.213702 11.5672 0.307779 11.6707C0.401856 11.7741 0.516457 11.8569 0.644281 11.9136C0.772104 11.9704 0.910348 11.9999 1.0502 12.0003C1.29479 12.0034 1.53174 11.9151 1.71466 11.7527C1.89759 11.5903 2.01332 11.3655 2.0392 11.1223C2.26182 8.63303 3.40718 6.31698 5.25028 4.62911C7.09339 2.94125 9.50102 2.00357 12.0002 2.00028Z" fill="white"/>
			<path id="Vector_2" d="M22.951 12.0001C22.7064 11.997 22.4695 12.0852 22.2865 12.2476C22.1036 12.41 21.9879 12.6348 21.962 12.8781C21.7967 14.7813 21.0889 16.5972 19.9227 18.1103C18.7564 19.6234 17.1806 20.7702 15.3821 21.4147C13.5837 22.0591 11.6382 22.1742 9.77635 21.7462C7.91451 21.3182 6.21449 20.3652 4.878 19.0001H8C8.26522 19.0001 8.51957 18.8947 8.70711 18.7072C8.89464 18.5197 9 18.2653 9 18.0001C9 17.7349 8.89464 17.4805 8.70711 17.293C8.51957 17.1054 8.26522 17.0001 8 17.0001H3.857C3.6131 17 3.37156 17.0479 3.1462 17.1412C2.92084 17.2345 2.71607 17.3712 2.54361 17.5437C2.37115 17.7162 2.23436 17.9209 2.14109 18.1463C2.04781 18.3717 1.99987 18.6132 2 18.8571V23.0001C2 23.2653 2.10536 23.5197 2.29289 23.7072C2.48043 23.8948 2.73478 24.0001 3 24.0001C3.26522 24.0001 3.51957 23.8948 3.70711 23.7072C3.89464 23.5197 4 23.2653 4 23.0001V20.9221C5.65283 22.4107 7.68741 23.4097 9.8759 23.8074C12.0644 24.205 14.3204 23.9856 16.3912 23.1737C18.4621 22.3617 20.266 20.9893 21.601 19.2102C22.936 17.431 23.7494 15.3153 23.95 13.1001C23.9629 12.9608 23.9467 12.8204 23.9023 12.6878C23.858 12.5551 23.7865 12.4332 23.6924 12.3297C23.5983 12.2262 23.4837 12.1435 23.3559 12.0867C23.2281 12.03 23.0899 12.0005 22.95 12.0001H22.951Z" fill="white"/>
		</g>
		<defs>
			<clipPath id="clip0_1059_1833">
				<rect width="24" height="24" fill="white"/>
			</clipPath>
		</defs>
	</svg>
  );
};

export default Rotate;
