import styles from "./XPVibes.module.css";
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Helper, } from "../components";
import { RightBigArrow, BackArrow, CloseBlack, } from '../icons';


const XPVibes = () => {
	const navigate = useNavigate();
	const { height, width } = Helper.getWindowDimensions();
	const [currentStep, setCurrentStep] = useState(1);
  	const [dayToDaySelectedVibes, setDayToDaySelectedVibes] = useState([]);
  	const [aspirationalSelectedVibes, setAspirationalSelectedVibes] = useState([]);
  	const [step1BtnDisabled, setStep1BtnDisabled] = useState(true);
  	const [step2BtnDisabled, setStep2BtnDisabled] = useState(true);
  	const [isTourOpen, setIsTourOpen] = useState(true);

	setTimeout(
	  () => {
	  	if (isTourOpen) setIsTourOpen(false)
	  },
	  5000
	);

	const closeTour = (e) => {
		e.stopPropagation();
		setIsTourOpen(false);
	};

	const routeChange = (e, url) => {
	    navigate(url);
	};

	const submitFinalForm = (e) => {
		window.dayToDaySelectedVibes = dayToDaySelectedVibes;
		window.aspirationalSelectedVibes = aspirationalSelectedVibes
		navigate('/myvibes');
	}

	const step1ImagesData = [
	  {
	    id: "An eco-conscious Ape",
	    src: "/care-nature.webp",
	    label: '“I care about nature” ☘️',
	  },
	  {
	    id: "Ape using Social media",
	    src: "/social-media.webp",
	    label: '“I’m super active on social media”🎉',
	  },
	  {
	    id: "Socially intellectual Ape",
	    src: "/bookworm.webp",
	    label: '“I’m a bookworm” 📚',
	  },
	  {
	    id: "Spiritual Ape",
	    src: "/spirituality.webp",
	    label: '“I seek Spirituality” 🧘',
	  },
	  {
	    id: "Foodie Ape",
	    src: "/food.webp",
	    label: '“Food is my soul” 🥗',
	  },
	  {
	    id: "Party loving Ape",
	    src: "/party-starter.webp",
	    label: '“I’m a Party Starter”🥳',
	  },
	  {
	    id: "Artisitc Ape",
	    src: "/art.webp",
	    label: '“Art is my life” 🎨',
	  },
	  {
	    id: "Fitness lover Ape",
	    src: "/workout.webp",
	    label: '“I work out religiously” 🤸',
	  },
	  {
	    id: "Sports fanatic Ape",
	    src: "/sports.webp",
	    label: '“Live Sports gives me kick”⚽',
	  },
	  {
	    id: "Stock/Business Ape",
	    src: "/numberss.webp",
	    label: '“Numbers are my super-power” 🔢',
	  },
	  {
	    id: "Shopping Ape",
	    src: "/shopaholic.webp",
	    label: '“I’m a Shopaholic”🛍️',
	  },
	  {
	    id: "Pop culture Ape",
	    src: "/pop-culture.webp",
	    label: '“Pop Culture is my game” 🎬',
	  },
	];

	const step2ImagesData = [
	  {
	    id: "Offbeat Ape",
	    src: "/offbeat.webp",
	    label: '“I like to explore offbeat destinations”',
	  },
	  {
	    id: "Apes in love",
	    src: "/romantic-partner.webp",
	    label: '“I travel with my romantic partner”',
	  },
	  {
	    id: "Street scenes Ape",
	    src: "/backpacker.webp",
	    label: '“I’m more of  a backpacker”',
	  },
	  {
	    id: "Art lover Ape",
	    src: "/artsy-places.webp",
	    label: '“I’m intrigued to artsy places”',
	  },
	  {
	    id: "Luxury seeker Ape",
	    src: "/luxury-comfort.webp",
	    label: '“I am spoiled with Luxury & Comfort”',
	  },
	  {
	    id: "Slow traveling Ape",
	    src: "/slowtravel.webp",
	    label: '“I seek Slow Travel”',
	  },
	  {
	    id: "Wellness lover Ape",
	    src: "/presentmoment.webp",
	    label: '“I seek to be present in the moment”',
	  },
	  {
	    id: "Cultural enthusiast Ape",
	    src: "/local-culture.webp",
	    label: '“I like to explore local culture”',
	  },
	  {
	    id: "Extreme adventure Ape",
	    src: "/adrenaline-rush.webp",
	    label: '“I need adrenaline rush”',
	  },
	  {
	    id: "Nature lover Ape",
	    src: "/travel-consciously.webp",
	    label: '“I travel Consciously”',
	  },
	  {
	    id: "Wildlife lover Ape",
	    src: "/wildlife.webp",
	    label: '“Exploring wildlife is love”',
	  },
	  {
	    id: "Biker Ape",
	    src: "/rider.webp",
	    label: '“I am a rider”',
	  },
	];

	const toggleDayToDayVive = (vibeId) => {
		let currentSelectedVibes = [];
		setDayToDaySelectedVibes((prevSelected) => {
			if (prevSelected.includes(vibeId)) {
				currentSelectedVibes = prevSelected.filter((id) => id !== vibeId);
			} else {
				currentSelectedVibes = [...prevSelected, vibeId];
			}


			if (currentSelectedVibes.length >= 3 && currentSelectedVibes.length <= 6) {
				setStep1BtnDisabled(false);
			} else {
				setStep1BtnDisabled(true);
			}

			return currentSelectedVibes;
		});
	};

	const toggleAspirationalVive = (vibeId) => {
		let currentSelectedVibes = [];
		setAspirationalSelectedVibes((prevSelected) => {
			if (prevSelected.includes(vibeId)) {
				currentSelectedVibes = prevSelected.filter((id) => id !== vibeId);
			} else {
				currentSelectedVibes = [...prevSelected, vibeId];
			}


			if (currentSelectedVibes.length >= 3 && currentSelectedVibes.length <= 6) {
				setStep2BtnDisabled(false);
			} else {
				setStep2BtnDisabled(true);
			}

			return currentSelectedVibes;
		});
	};

  	const moveToNextStep = (event) => {
  		event.preventDefault();

  		if (currentStep == 1 && !step1BtnDisabled) {
  			setCurrentStep(currentStep + 1);
  			document.body.scrollTo(0, 0);
  		}
  	};

	const moveToPrevStep = (event) => {
  		event.preventDefault();
  		if (currentStep === 1 || currentStep > 1 && (dayToDaySelectedVibes.length === 0 && aspirationalSelectedVibes.length === 0)) {
  			window.location = '/';
  		} else {
  			setCurrentStep(currentStep - 1);
			document.body.scrollTo(0, 0);
  		}
  	};

  	return (
      	<div className={styles.vibeCheck}>
	        {currentStep == 1 && <div className={styles.vibeCheckStep1}>
	        	<i onClick={(e) => { Helper.trackAnalytics('XPVibes', 'Click_Prev_from_daily_interest', 'Prev'); moveToPrevStep(e); }} className={styles.backArrow}><BackArrow /></i>
				<h2>Your Daily Vibe <span className={styles.notbold}>(step: 1/2)</span></h2>
				<label>Pick 3–6 images that match your everyday interests and activities. Let's see what you’re into!</label>
				<span className={styles.skipBtn} onClick={(e) => {Helper.trackAnalytics('XPVibes', 'Click_Skip_from_daily_interest', 'Skip'); routeChange(e, '/');}}>Skip</span>
				<div className={styles.imageGrid}>
					{step1ImagesData.map((image, i) => (
						<div key={image.id} style={{width: ((width - 60) / 2) - 5, height: ((width - 60) / 2) - 5, background: '#D9D9D9', borderRadius: '12px'}} className={`${dayToDaySelectedVibes.includes(image.id) ? styles.imageTileSelected : styles.imageTile}`}
							onClick={() => toggleDayToDayVive(image.id)}>
							
							{i === 1 && isTourOpen && <div className={styles.tourWrap}>
								<i onClick={(e) => closeTour(e)} className={styles.closeIcon}><CloseBlack /></i>
								<label>Click on the image to select one.</label>
								<div className={styles.leftArrow}></div>
							</div>}

							<img src={image.src} alt={`Image ${image.id}`} />
							<span className={styles.imgLabel}>{image.label}</span>
							{dayToDaySelectedVibes.includes(image.id) && <img className={styles.checkIcon} src="/check-icon.png" />}
						</div>
					))}
					<button type="button" className={styles.nextButton} disabled={step1BtnDisabled} onClick={(e) => { Helper.trackAnalytics('XPVibes', 'Click_Next_from_daily_interest', 'Next'); moveToNextStep(e); }}>Next <RightBigArrow /></button>
				</div>
	        </div>}

	        {currentStep == 2 && <div className={styles.vibeCheckStep2}>
	        	<i onClick={(e) => { Helper.trackAnalytics('XPVibes', 'Click_Prev_from_travelling_interest', 'Prev'); moveToPrevStep(e); }} className={styles.backArrow}><BackArrow /></i>
				<h2>Your Travel Vibe <span className={styles.notbold}>(step: 2/2)</span></h2>
				<label>Select 3-6 images that resonate with your travel style and aspirations. Let's find out where you're drawn to!</label>
				<span className={styles.skipBtn} onClick={(e) => {Helper.trackAnalytics('XPVibes', 'Click_Skip_from_travelling_interest', 'Skip'); routeChange(e, '/');}}>Skip</span>
				<div className={styles.imageGrid}>
					{step2ImagesData.map((image) => (
						<div key={image.id} className={`${aspirationalSelectedVibes.includes(image.id) ? styles.imageTileSelected : styles.imageTile}`}
							onClick={() => toggleAspirationalVive(image.id)}>
							<img src={image.src} alt={`Image ${image.id}`} />
							<span className={styles.imgLabel}>{image.label}</span>
							{aspirationalSelectedVibes.includes(image.id) && <img className={styles.checkIcon} src="/check-icon.png" />}
						</div>
					))}
					<button type="button" className={styles.nextButton} disabled={step2BtnDisabled} onClick={(e) => { Helper.trackAnalytics('XPVibes', 'Click_Next_from_travelling_interest', 'Show my vibe'); submitFinalForm(e); }}>Next <RightBigArrow /></button>
				</div>
	        </div>}
      	</div>
  	);
};

export default XPVibes;
