import styles from "./BookExperience.module.css";
import { Back, Helper, ContactCreator, LoginHelper, } from "../components";
import { RightBigArrow, BackArrowWhite, Plus, Minus, CloseWhite, } from "../icons";
import { Oval } from  'react-loader-spinner';
import { useParams, useNavigate, } from "react-router-dom";
import React, { useEffect, useState, } from "react";


const BookExperience = () => {
	const navigate = useNavigate();
	const { slug } = useParams();
	const { height, width } = Helper.getWindowDimensions();
	const [experience, setExperience] = useState({});
	const [adultsCount, setAdultsCount] = useState(1);
	const [selectedRecurrence, setSelectedRecurrence] = useState({});
	const [childrenCount, setChildrenCount] = useState(0);
	const [invalidExperienceId, setInvalidExperienceId] = useState(false);
	const [contactCreatorStorage, setContactCreatorStorage] = useState('contactCreatorLeads');
	const [openContactCreator, setOpenContactCreator] = useState(false);
	const [experienceToContact, setExperienceToContact] = useState({});
	const [currentStep, setCurrentStep] = useState(1);
	const [step1Disabled, setStep1Disabled] = useState(true);
	const [step2Disabled, setStep2Disabled] = useState(true);
	const user = LoginHelper.getUser() || {};
	const [formState, setFormState] = useState({userPhone: user.mobile ? user.mobile.toString() : '', 0: {
		name: user.name,
		age: user.age,
		gender: user.gender,
	}});
	const [formErrorState, setFormErrorState] = useState({userPhone: false,});
	const [showPriceBreakUp, setShowPriceBreakUp] = useState(false);
	const [submittingForm, setSubmittingForm] = useState(false);

	const getAnalyticsCategory = () => {
		try {
	      return `BookExperience_${experience.title.split(' ').join('_')}`;
	    } catch(err) {
	      return 'BookExperience';
	    }
	};

	const trackAnalytics = (action, label) => {
		Helper.trackAnalytics(getAnalyticsCategory(), action, label);
	};

	const routeChange = (e, url) => {
		trackAnalytics(`Click_${Helper.titleCase(url.split('/')[url.split('/').length - 1].replaceAll('-', '_'))}`, 'Navigate');
    	navigate(url);
  	};

  	const setData = (exp, storage, open) => {
		setExperienceToContact(exp);
		setContactCreatorStorage(storage);
		setOpenContactCreator(open);
	};

  	const decreaseCount = (e, type) => {
  		if (type === 'adults') {
  			if (adultsCount !== 1) setAdultsCount(adultsCount - 1);
  			trackAnalytics('Click_Decrease_adults_count', adultsCount - 1);
  		} else {
  			if (childrenCount !== 0) setChildrenCount(childrenCount - 1);
  			trackAnalytics('Click_Decrease_children_count', childrenCount - 1);
  		}
  	};

  	const increaseCount = (e, type) => {
  		if (type === 'adults') {
  			if (adultsCount + 1 <= experience.groupSize.max) setAdultsCount(adultsCount + 1);
  			trackAnalytics('Click_Increase_adults_count', adultsCount + 1);
  		} else {
  			setChildrenCount(childrenCount + 1);
  			trackAnalytics('Click_Increase_children_count', childrenCount + 1);
  		}
  	};

  	const selectRecurrence = (e, recurrence) => {
  		setSelectedRecurrence(recurrence);
  		setStep1Disabled(false);
  		trackAnalytics('Click_Select_Date', Helper.formatDate(new Date(recurrence.dates.start)));
  	};

  	const moveToNextStep = (event) => {
  		event.preventDefault();
  		setCurrentStep(currentStep + 1);
  		document.body.scrollTo(0, 0);
  	};

	const moveToPrevStep = (event) => {
  		event.preventDefault();
  		setCurrentStep(currentStep - 1);
		document.body.scrollTo(0, 0);
  	};

  	const validateMemberDetails = () => {
  		let isValid = true;

  		for (let i = 0; i < adultsCount; i++) {
  			if (!formState[i]) isValid = false;
  			else {
  				if (!formState[i].name) isValid = false;
  				if (!formState[i].age || formState[i].age < 5) isValid = false;
  			}
  		}

  		if (isValid && formState.userPhone.length !== 10) isValid = false;
  		setStep2Disabled(!isValid);
  	};

  	const onChangeInput = (e, type, i, val) => {
  		e.preventDefault();

  		if (type === 'phone') {
  			formErrorState['userPhone'] = false;
  			setFormErrorState({...formErrorState});
		    if (e.target.value.length < 11) {
		    	formState['userPhone'] = e.target.value;
		    	setFormState({...formState});
		    }
  		}

  		if (type === 'name') {
  			formErrorState[i] = formErrorState[i] || {};
  			formErrorState[i].name = false;
  			setFormErrorState({...formErrorState});
  			const result = e.target.value.replace(/[^a-z ]/gi, '');
    		formState[i] = formState[i] || {};
  			formState[i].name = result;
  			setFormState({...formState});
  		}

  		if (type === 'age') {
  			formErrorState[i] = formErrorState[i] || {};
  			formErrorState[i].age = false;
  			setFormErrorState({...formErrorState});
    		formState[i] = formState[i] || {};
    		if (e.target.value.length < 3)
	  			formState[i].age = Number(e.target.value);
	  			setFormState({...formState});
  		}

  		if (type === 'gender') {
    		formState[i] = formState[i] || {};
	  		formState[i].gender = val;
	  		setFormState({...formState});
  		}

  		validateMemberDetails();
  	};

  	const reserveParticipation = async (e) => {
  		e.preventDefault();

  		const guests = [];
  		for (let i = 0; i < adultsCount; i++) {
  			guests.push({
  				name: formState[i].name,
  				age: formState[i].age,
  				mobile: formState.userPhone,
  				gender: formState[i].gender,
  			});
  		}

	    if (!submittingForm) {
	      	setSubmittingForm(true);
	      	await fetch(`${global.config.SERVER_URL}/api/v1/bookings`, {
	        	method: 'POST',
	        	body: JSON.stringify({
		          	experience: experience['_id'],
		          	recurrence: selectedRecurrence['_id'],
		          	price: experience.unitPrice * adultsCount,
		          	children: childrenCount,
		          	guests,
	        	}),
	        	headers: {
	           		'Content-type': 'application/json; charset=UTF-8',
	           		...Helper.getCommonHeaders(),
	        	},
	        })
	        .then((response) => response.json())
	        .then((data) => {
	          	setSubmittingForm(false)
	           	if (data.status == 'success') {
	           		setCurrentStep(currentStep + 1);
  					document.body.scrollTo(0, 0);
	           }
	        })
	        .catch((err) => {
	          	setSubmittingForm(false)
	          	console.log(err.message);
	        });
	    }
  	};

  	const getMembersCard = () => {
  		const cards = [];
  		for (let i = 0; i < adultsCount; i++) {
			cards.push(<li key={i} className={styles.memberDetail}>
				<form className={styles.memberForm}>
					<h4>Member {i + 1} {i === 0 ? '(You)' : ''}</h4>
					{i !== 0 && <i className={styles.removeMember} onClick={e => decreaseCount(e, 'adults')} >
						<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
							<path d="M15.7503 5.24999C15.5862 5.08596 15.3637 4.9938 15.1317 4.9938C14.8997 4.9938 14.6772 5.08596 14.5131 5.24999L10.5003 9.26274L6.48758 5.24999C6.32349 5.08596 6.10097 4.9938 5.86895 4.9938C5.63694 4.9938 5.41442 5.08596 5.25033 5.24999C5.08629 5.41408 4.99414 5.6366 4.99414 5.86862C4.99414 6.10064 5.08629 6.32316 5.25033 6.48724L9.26308 10.5L5.25033 14.5127C5.08629 14.6768 4.99414 14.8993 4.99414 15.1314C4.99414 15.3634 5.08629 15.5859 5.25033 15.75C5.41442 15.914 5.63694 16.0062 5.86895 16.0062C6.10097 16.0062 6.32349 15.914 6.48758 15.75L10.5003 11.7372L14.5131 15.75C14.6772 15.914 14.8997 16.0062 15.1317 16.0062C15.3637 16.0062 15.5862 15.914 15.7503 15.75C15.9144 15.5859 16.0065 15.3634 16.0065 15.1314C16.0065 14.8993 15.9144 14.6768 15.7503 14.5127L11.7376 10.5L15.7503 6.48724C15.9144 6.32316 16.0065 6.10064 16.0065 5.86862C16.0065 5.6366 15.9144 5.41408 15.7503 5.24999Z" fill="#1C1C1E"/>
						</svg>
					</i>}

					<div>
	                  <input className={formErrorState[i] && formErrorState[i].name ? styles.invalid : styles.valid} type="text" placeholder="Name *" value={formState[i] && formState[i].name || ''} onChange={e => onChangeInput(e, 'name', i)} />
	                </div>

	                <div>
		                <input className={formErrorState[i] && formErrorState[i].age ? styles.invalid : styles.valid} type="number" placeholder="Age (Above 4 Years) *" value={formState[i] && formState[i].age || ''} onChange={e => onChangeInput(e, 'age', i)} />
		            </div>

			        <div className={styles.genderInput}>
			        	<span className={styles.genderHeading}>Gender</span>
			        	<label className={(formState[i] || {}).gender === 'male' ? styles.selected : ''} onClick={e => onChangeInput(e, 'gender', i, 'male')}><img src={(formState[i] || {}).gender === 'male' ? '/radio-selected.svg' : '/radio-unselected.svg'} />Male</label>
			        	<label className={(formState[i] || {}).gender === 'female' ? styles.selected : ''} onClick={e => onChangeInput(e, 'gender', i, 'female')}><img src={(formState[i] || {}).gender === 'female' ? '/radio-selected.svg' : '/radio-unselected.svg'} />Female</label>
			        	<label className={(formState[i] || {}).gender === 'other' ? styles.selected : ''} onClick={e => onChangeInput(e, 'gender', i, 'other')}><img src={(formState[i] || {}).gender === 'other' ? '/radio-selected.svg' : '/radio-unselected.svg'} />Other</label>
			        </div>

			        {i === 0 && <div>
			          <input className={formErrorState.userPhone ? styles.invalid : styles.valid} type="number" placeholder="Phone No. (+91) *" value={formState.userPhone} onChange={e => onChangeInput(e, 'phone', i)} />
			        </div>}
		        </form>
			</li>)
		}

		return cards.slice();
  	}

  	const getGuestsList = () => {
  		const guests = [];
  		for (let i = 0; i < adultsCount; i++) {
			guests.push(<li key={i} className={styles.guestListItem}>
				{i + 1}. {formState[i].name}
			</li>)
		}

		return guests;
  	};
 
	useEffect(() => {
	    fetch(`${global.config.SERVER_URL}/api/v1/experiences?slug=${slug}`)
	      	.then((response) => response.json())
	      	.then((res) => {
	        	if (res.data) setExperience(res.data);
	        	else setInvalidExperienceId(true);
	     	})
	     	.catch((err) => {
	     		setInvalidExperienceId(true);
	        	console.log(err.message);
	     	});
	}, {});

  	return(
   		<div className={styles.bookExperienceContainer}>
   			{invalidExperienceId && 
		        <h1 style={{ textAlign: 'center', margin: '80px 50px'}}>Apologies! XPHive does not have this experience listed.</h1>
		    }

		    {!invalidExperienceId && Object.keys(experience).length == 0 ? <Oval
		        height={40}
		        width={40}
		        color="#1C1C1E"
		        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
		        wrapperClass=""
		        visible={true}
		        ariaLabel='oval-loading'
		        secondaryColor="#dbe8f6"
		        strokeWidth={4}
		        strokeWidthSecondary={4}
		    /> : '' }

		    { Object.keys(experience).length != 0 ?
		        <div>
		        	{currentStep === 1 && <div>
		        		<Back analyticsCategory={getAnalyticsCategory()} style={{position: 'relative', left: '11px', top: '33px', width: '28px'}} />
			        	<div className={styles.topSection}>
			        		<h3>{experience.title}</h3>
			        		<img className={styles.experienceImg} src={experience.images[0]} />
			        		<p className={styles.description}>{experience.description}</p>
			        	
				        	<div className={styles.guestsInput}>
								<h3>Who’s Coming?</h3>
								<div className={styles.adults}>
									<label>Adults</label>
									<div className={styles.counts}>
										<i className={styles.minus} onClick={e => decreaseCount(e, 'adults')}>
											<Minus />
										</i>
										<span>{adultsCount < 10 ? '0' + adultsCount : adultsCount}</span>
										<i className={styles.plus} onClick={e => increaseCount(e, 'adults')}>
											<Plus />
										</i>
									</div>
								</div>
								<div className={styles.children}>
									<label>Children</label>
									<div className={styles.counts}>
										<i className={styles.minus} onClick={e => decreaseCount(e, 'children')}>
											<Minus />
										</i>
										<span>{childrenCount < 10 ? '0' + childrenCount : childrenCount}</span>
										<i className={styles.plus} onClick={e => increaseCount(e, 'children')}>
											<Plus />
										</i>
									</div>
								</div>
				        	</div>
			        	</div>

			        	<div className={styles.recurrenceInput}>
			        		<h3>Select Dates</h3>
			        		<ul className={styles.recurrencesList}>
				        		{experience.recurrences.map((recurrence, i) => {
				        			return(<li key={i} className={recurrence['_id'] === selectedRecurrence['_id'] ? styles.selectedRecurrence : ''}>
				        				{`${new Date(recurrence.dates.start).getDate()} ${new Date(recurrence.dates.start).toLocaleString('default', { month: 'short' })}  - ${new Date(recurrence.dates.end).getDate()} ${new Date(recurrence.dates.end).toLocaleString('default', { month: 'short' })}`}
				        				<label className={styles.unitPrice}>₹ {experience.unitPrice.toLocaleString()}<span> / person</span></label>
				        				<button style={width < 301 ? {position: 'relative', float: 'right', right: '0', marginBottom: '20px'} : {}} onClick={e => selectRecurrence(e, recurrence)} className={styles.chooseBtn}>Choose</button>
				        			</li>)
				        		})}

				        		<li className={styles.alternateDate}>
				        			<p>Preferred Date Not available?</p>
				        			<button onClick={(e) => { e.stopPropagation(); setData(experience, 'contactCreatorLeads', true); trackAnalytics('Click_Request_alternative_date', 'Need alternate date');}}>Request Alternative Date</button>
				        		</li>
			        		</ul>
			        	</div>

			        	<ContactCreator analyticsCategory={getAnalyticsCategory()} hideCommentsBtn={true} open={openContactCreator} brand={experience.brand} experience={experience} storage={contactCreatorStorage} setData={setData} />

			        	<button disabled={step1Disabled} className={styles.nextBtn} onClick={(e) => { e.stopPropagation(); trackAnalytics('Click_Next_post_date_option', `${Helper.formatDate(new Date(selectedRecurrence.dates.start))}`); moveToNextStep(e);}}>Next <RightBigArrow /></button>
			        </div>}

			        {currentStep === 2 && <div className={styles.memberDetails}>
			        	<div className={styles.topWrap}>
			        		<i onClick={(e) => { trackAnalytics('Click_Back_from_member_details', `${Helper.formatDate(new Date(selectedRecurrence.dates.start))}`); moveToPrevStep(e); }} className={styles.backArrow}><BackArrowWhite /></i>
			        		<p>Hello...!<br/><br/>To join <span>{experience.title}</span> on <span>{Helper.formatDate(new Date(selectedRecurrence.dates.start), 'long', false)}</span>, kindly provide your information below.</p>
			        	</div>

			        	<ul className={styles.membersList}>
			        		{getMembersCard()}
			        	</ul>

			        	<div className={styles.addGuest} onClick={e => increaseCount(e, 'adults')}>
			        		<Plus fill={'#1C1C1E'} />
			        		<label>Add Another Guest</label>
			        	</div>

			        	<p className={styles.confirmationMsg}>Share booking information on provided contact number.</p>
			        	<button disabled={step2Disabled} className={styles.nextBtn} onClick={(e) => { e.stopPropagation(); trackAnalytics('Click_Next_post_member_details', formState.userPhone); moveToNextStep(e);}}>Next <RightBigArrow /></button>
			        </div>}

			        {currentStep === 3 && <div className={styles.reviewBooking}>
			        	<div className={styles.reviewExperience}>
			        		<i onClick={(e) => { trackAnalytics('Click_Back_from_review_booking', formState.userPhone); moveToPrevStep(e); }} className={styles.backArrow}><BackArrowWhite /></i>
			        		<h3>Review Your Booking Details</h3>
			        		<div className={styles.basicInfo}>
			        			<img className={styles.experienceImg} src={experience.images[0]} />
			        			<div className={styles.titleAndDesc}>
			        				<h5>{experience.title}</h5>
			        				<p>{experience.description}</p>

			        				<span className={styles.selectedRecurrenceDate}>{`${new Date(selectedRecurrence.dates.start).getDate()} ${new Date(selectedRecurrence.dates.start).toLocaleString('default', { month: 'short' })}  - ${new Date(selectedRecurrence.dates.end).getDate()} ${new Date(selectedRecurrence.dates.end).toLocaleString('default', { month: 'short' })}`}</span>
			        				{Object.keys(experience.transport).map((key, i) => 
					                  <span className={styles.transport} key={i}>{key} - {experience.transport[key]}</span>
					                )}
			        			</div>
			        		</div>

			        		<div className={styles.verifyGuest}>
			        			<h4>Your Guest List</h4>
			        			<ul>{getGuestsList()}</ul>
			        		</div>
			        		<div className={styles.editWrap}><span onClick={(e) => { trackAnalytics('Click_Edit_guest_details', formState.userPhone); moveToPrevStep(e); }}>Edit</span></div>
			        	</div>

			        	<div className={styles.priceDetails}>
			        		<h3>Price Details</h3>
			        		<ul>
			        			<li>
			        				{`₹${experience.unitPrice.toLocaleString()} x ${adultsCount} ${adultsCount > 1 ? 'adults' : 'adult' }`}
			        				<span>{`₹${(experience.unitPrice * adultsCount).toLocaleString()}`}</span>
			        			</li>

			        			{showPriceBreakUp && <li style={{display: 'none'}}>
			        				Price breakdown for this experience, with a budget of ₹{experience.unitPrice.toLocaleString()} per person:
			        				<ul className={styles.priceBreakupList}>
			        					{Object.keys(experience.priceDetails).map((key, i) => 
                							<li key={i}>{key}: {experience.priceDetails[key]} per person</li>
              							)}
			        				</ul>
			        				<p style={{fontWeight: 600}} className={styles.total}>Total for {adultsCount} {adultsCount > 1 ? 'members' : 'member' } : {`₹${(experience.unitPrice * adultsCount).toLocaleString()}`}/-</p>
			        			</li>}

			        			<li className={styles.totalPrice}>
			        				Total (INR)
			        				<span>{`₹${(experience.unitPrice * adultsCount).toLocaleString()}`}</span>
			        			</li>
			        		</ul>
			        		<span style={{display: 'none'}} onClick={e => setShowPriceBreakUp(!showPriceBreakUp)} className={styles.togglePriceBreakup}>{showPriceBreakUp ? 'Show Less' : 'More Info'}</span>
			        	</div>

			        	<div className={styles.policies}>
			        		<h3>Things to Know</h3>

			        		<a href="/terms-policy" target="_blank">Terms & Conditions</a><br />
			        		<a href="/cancellation-policy" target="_blank">Cancellation Policy</a><br />
			        		<a href="/refund-policy" target="_blank">Refund Policy</a>
			        	</div>

			        	<p className={styles.agreePolicies}>By selecting the button below, you agree to the <span>Terms and Conditions</span>, <span>Cancellation Policy</span>, <span>Refund Policy</span> and <span>XPhive’s Guest Guidlines</span>.</p>
			        	<button className={styles.nextBtn} onClick={(e) => { e.stopPropagation(); trackAnalytics('Click_Reserve_participation', formState.userPhone); reserveParticipation(e);}}>Reserve Your Participation <RightBigArrow /></button>
			    	</div>}

			    	 {currentStep === 4 && <div>
			    	 	<div className={styles.bookingSuccess}>
				    	 	<i style={{float: 'right', 'cursor': 'pointer'}} onClick={e => routeChange(e, `/experiences/${experience.slug}`)}><CloseWhite /></i>
				    	 	<h3><span>Thanks!</span>We’ve Received Your Confirmation.</h3>
			    	 	</div>
			    	 	<p className={styles.confirmationMsg}>You will receive a confirmation message shortly. Please save it as it may contain additional details for your reference.</p>
			    	 </div>}
		        </div>
		    : ''}
   		</div>
  	);
};

export default BookExperience;
