import styles from "./CommunityInvite.module.css";
import React, { useState, useEffect } from 'react';  
import Helper from './Helper';


const CommunityInvite = (props) => {
  const [formState, setFormState] = useState({
    communityId: props.community['_id'],
    phone: '',
    name: '',
    email: '',
  });
  const [formErrorState, setFormErrorState] = useState({
    phone: false,
    name: false,
    email: false,
  });
  const [submittingForm, setSubmittingForm] = useState(false);
  const [leadSubmitted, setLeadSubmitted] = useState(Helper.storage.getItem(`CommunityInvite_${props.community['_id']}`) || false);

  const onClickChoice = (e, que, val) => {
    formState[que] = formState[que] || [];
    if (formState[que].includes(val)) {
      formState[que] = formState[que].filter(av => av !== val);
    } else {
      formState[que].push(val);
    }
    setFormState({...formState});
  };

  const onChangeInput = (e, type) => {
      e.preventDefault();

      if (type === 'phone') {
        formErrorState['phone'] = false;
        setFormErrorState({...formErrorState});
        if (e.target.value.length < 11) {
          formState['phone'] = e.target.value;
          setFormState({...formState});
        }
      } else if (type === 'name') {
        formErrorState.name = false;
        setFormErrorState({...formErrorState});
        const result = e.target.value.replace(/[^a-z ]/gi, '');
        formState.name = result;
        setFormState({...formState});
      } else if (type === 'email') {
        formErrorState.email = false;
        setFormErrorState({...formErrorState});
        formState.email = e.target.value;
        setFormState({...formState});
      } else {
        formErrorState[type] = false;
        setFormErrorState({...formErrorState});
        formState[type] = e.target.value;
        setFormState({...formState});
      }
    };

  const setLeads = () => {
    setLeadSubmitted(true);
    Helper.storage.setItem(`CommunityInvite_${props.community['_id']}`, true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formState.name) {
      formErrorState['name'] = true;
      setFormErrorState({...formErrorState});
    }

    if (formState.phone.length != 10) {
      formErrorState['phone'] = true;
      setFormErrorState({...formErrorState});
    }
    
    if (!formState.email) {
      formErrorState['email'] = true;
      setFormErrorState({...formErrorState});
    }


    if (!submittingForm && formState.name && formState.phone && formState.email && !formErrorState.name 
      && !formErrorState.phone && !formErrorState.email) {
      Helper.trackAnalytics(`CommunityInvite_${props.community.title.split(' ').join('_')}`, 'Click_Submit_details', formState.phone);
      setSubmittingForm(true);
      props.setShowLoader(true);
      const details = {...formState};
      delete details.name;
      delete details.email;
      delete details.phone;

      await fetch(`${global.config.SERVER_URL}/api/v1/leads`, {
        method: 'POST',
        body: JSON.stringify({
          name: formState.name,
          email: formState.email,
          phone: formState.phone,
          details,
          type: 'communityInvite',
        }),
        headers: {
           'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .then((response) => response.json())
        .then((data) => {
          setSubmittingForm(false);
          props.setShowLoader(false);
          if (data.status == 'success') setLeads();
        })
        .catch((err) => {
          setSubmittingForm(false);
          props.setShowLoader(false);
          console.log(err.message);
        });
    }
  };
  
  return (
      <div className={styles.communityInviteContainer}>
        {!leadSubmitted &&
          <div>
            <h1 className={styles.heading}>Invite form</h1>

            <form onSubmit={handleSubmit}>
              <div>
                <label className={styles.label}>What is your name? *</label>
                <input className={formErrorState.name ? styles.invalid : styles.valid} type="text" placeholder="Name" value={formState.name} onChange={e => onChangeInput(e, 'name')} />
              </div>

              <div>
                <label className={styles.label}>Please share your WhatsApp number... *</label>
                <input className={formErrorState.phone ? styles.invalid : styles.valid} type="number" placeholder="+91 -" value={formState.phone} onChange={e => onChangeInput(e, 'phone')} />
              </div>

              <div>
                <label className={styles.label}>Please share your Mail ID... *</label>
                <input className={formErrorState.email ? styles.invalid : styles.valid} type="email" placeholder="Email" value={formState.email} onChange={e => onChangeInput(e, 'email')} />
              </div>

              {props.community.questionnaires && props.community.questionnaires.map((inputQue, i) => {
                return( inputQue.type !== 'choice' ?
                  (inputQue.type === 'textarea' ?
                    <div key={i}>
                      <label className={styles.label}>{inputQue.question}</label>
                      <textarea className={formErrorState[inputQue.question] ? styles.invalid : styles.valid} placeholder={inputQue.placeholder} value={formState[inputQue.question]} rows="3" onChange={e => onChangeInput(e, inputQue.question)} />
                    </div>
                    : <div key={i}>
                      <label className={styles.label}>{inputQue.question}</label>
                      <input className={formErrorState[inputQue.question] ? styles.invalid : styles.valid} type={inputQue.type} placeholder={inputQue.placeholder} value={formState[inputQue.question]} onChange={e => onChangeInput(e, inputQue.question)} />
                    </div>
                  ) : 
                  <div key={i}>
                    <label className={styles.label}>{inputQue.question}</label>
                    {inputQue.choices.map((choice, j) => {
                        return(<span key={j} onClick={e => onClickChoice(e, inputQue.question, choice)} className={(formState[inputQue.question] || []).includes(choice) ? styles.choiceSelected : styles.choice}>{choice}</span>)
                    })}
                </div>)
              })}

              <button type="submit">Request Your Invite</button>
            </form>
          </div>
        }

        {leadSubmitted && 
          <div>
            <h3 className={styles.success}>Thank you for showing interest! Wait while our team adds you to the community!</h3>
          </div>
        }
    </div>
  )
}

export default CommunityInvite;
