import styles from "./ExperienceDetails.module.css";
import { useNavigate, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import React, { useEffect, useState, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { Oval } from  'react-loader-spinner';
import { Back, Share, Favourite, Helper, Accordion, Experiences, ContactCreator, BookExperienceForm, } from "../components";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Backpack, Ellipse, RectangleSolid, Rectangle, Comments2, AngleRight, TimeIcon, ExperienceEllipseLeft, ExperienceEllipseRight, RightArrow, Rotate, } from '../icons';
import { renderToStaticMarkup } from 'react-dom/server';
import ReactPlayer from 'react-player';


const ExperienceDetails = () => {
  const navigate = useNavigate();
  const [experience, setExperience] = useState({});
  const [experienceToContact, setExperienceToContact] = useState({});
  const [similarExperience, setSimilarExperience] = useState([]);
  const [experienceSlug, setExperienceSlug] = useState(null);
  const { slug } = useParams();
  const { height, width } = Helper.getWindowDimensions();
  const [invalidExperienceId, setInvalidExperienceId] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [flippedCards, setFlippedCards] = useState([]);
  const [openContactCreator, setOpenContactCreator] = useState(false);
  const [contactCreatorStorage, setContactCreatorStorage] = useState('contactCreatorLeads');
  const itineraryAndTransportRef = useRef(null);
  const [transportHeight, setTransportHeight] = useState(0);
  const [variationNumber, setVariationNumber] = useState(0);

  const getAnalyticsCategory = () => {
    try {
      return `ExperienceDetails_${experience.title.split(' ').join('_')}`;
    } catch(err) {
      return 'ExperienceDetails';
    }
  };

  const trackAnalytics = (action, label) => {
    Helper.trackAnalytics(getAnalyticsCategory(), action, label);
  };

  const routeChange = (e, url) => {
    trackAnalytics(`Click_${Helper.titleCase(url.split('/')[url.split('/').length - 1].replaceAll('-', '_'))}`, `Navigate`);
    navigate(url);
  };

  const setData = (exp, storage, open) => {
    setExperienceToContact(exp);
    setContactCreatorStorage(storage);
    setOpenContactCreator(open);
  };

  const flipCard = (e, i, title) => {
    e.stopPropagation();
    let flippedCardIds = flippedCards.slice();
    if (flippedCardIds.includes(i)) {
      flippedCardIds = flippedCardIds.filter(key => key !== i);
      trackAnalytics(`Click_${Helper.titleCase(title.split(' ').join('_'))}`, `Flip Back`);
    } else {
      flippedCardIds.push(i);
      trackAnalytics(`Click_${Helper.titleCase(title.split(' ').join('_'))}`, `Flip`);
    }

    setFlippedCards(flippedCardIds);
  };

  useEffect(() => {
    if (itineraryAndTransportRef && itineraryAndTransportRef.current && transportHeight === 0) 
      setTransportHeight(itineraryAndTransportRef.current.clientHeight);
  });

  const fetchData = () => {
    fetch(`${global.config.SERVER_URL}/api/v1/experiences?slug=${slug}`)
      .then((response) => response.json())
      .then((res) => {
        if (res.data) {
          setExperience(res.data);
          const variationKey = `abTestNumber_${res.data._id}`;
          if (Helper.storage.getItem(variationKey)) {
            setVariationNumber(Helper.storage.getItem(variationKey));
          } else {
            const randomNumber = Math.floor(Math.random() * 10);
            setVariationNumber(randomNumber);
            Helper.storage.setItem(variationKey, randomNumber);
          }
        } else setInvalidExperienceId(true);

        setShowLoader(false);
     })
     .catch((err) => {
        setInvalidExperienceId(true);
        console.log(err.message);
     });

     fetch(`${global.config.SERVER_URL}/api/v1/experiences/similar?slug=${slug}`)
      .then((response) => response.json())
      .then((res) => {
        setSimilarExperience(res.data);
        setShowLoader(false);
     })
     .catch((err) => {
        console.log(err.message);
     });
  };

  useEffect(() => {
    fetchData();
    if (slug !== experienceSlug) setExperienceSlug(slug);
  }, {});

  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );

  const getImmersionsList = (immersions) => {
    return chunk(immersions, 2);
  };

  if (experienceSlug && slug !== experienceSlug) {
    setShowLoader(true);
    setExperienceSlug(slug);
    fetchData();
    setExperienceToContact({});
    setFlippedCards([])
    setOpenContactCreator(false);
    setTransportHeight(0);
  };

  return (
    <div className={styles.experienceDetails} style={{background: invalidExperienceId ? '#F2F2F7' : ''}}>
      {invalidExperienceId && <Back analyticsCategory={getAnalyticsCategory()} /> }
      {invalidExperienceId && 
        <h1 style={{ textAlign: 'center', margin: '80px 50px'}}>Apologies! XPHive does not have this experience listed.</h1>
      }

      {(!invalidExperienceId && Object.keys(experience).length == 0) || showLoader ? <Oval
        height={40}
        width={40}
        color="#1C1C1E"
        wrapperStyle={{'position': 'fixed', 'left': 'calc(50% - 20px)', 'top': 'calc(50% - 20px)'}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#dbe8f6"
        strokeWidth={4}
        strokeWidthSecondary={4}
      /> : '' }

      { !showLoader && Object.keys(experience).length != 0 && experience.shortExperience !== true ?
        <div className={styles.topContainerParent}>
          <span className={styles.xphiveLogo}><img src='/xphive-logo-text.png' /></span>
          <Back analyticsCategory={getAnalyticsCategory()} style={{position: 'relative', left: '15px', top: '-48px', width: '28px'}} />
          <div className={styles.shareAndFavourite}>
            <Share analyticsCategory={getAnalyticsCategory()} hideCircle={true} blackIcon={true} style={{position: 'absolute', right: '100px', top: '50px'}}/>
            <Favourite analyticsCategory={getAnalyticsCategory()} hideCircle={true} id={experience._id} storage={'experience'} style={{ position: 'absolute', top: '50px', left: 'unset', bottom: 'unset', right: '45px'}} />
          </div>

          <div className={styles.topContainer}>
            <div className="experienceImagesCarouselParent">
              <Carousel preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={20} autoPlay={window.innerWidth > 912} showArrows={false} infiniteLoop={true} stopOnHover={false} showThumbs={false} showStatus={false}>      
                  {experience.bannerImages.map((image, i) => {
                    return (
                        <div key={i} style={{width: width - 40, height: (width - 40) / 0.75}}>
                          {image.includes(".mp4") ? <ReactPlayer className={styles.videoContainer} url={image} muted={true} loop={true} playing={true} width={width - 40} height={(width - 40) * 1.33} /> : <img className={styles.bannerImage} src={image} />}
                        </div>
                    );
                  })}
                  
              </Carousel>
              <div className={styles.titleAndLocation}>
                <h4 className={styles.title}>{experience.title}</h4>
                <label className={styles.location}>Location - {`${experience.locations[0].city}, ${experience.locations[0].state}, ${experience.locations[0].country}`}</label>
              </div>
            </div>

            <div className={styles.infoWrap}>
              <div className={styles.infoInnerWrap}>
                <label className={styles.experienceSubtitle}>{experience.subtitle}</label>
                <p>{parse(experience.longDescription)}</p>
              </div>
              {experience.priceDetails && Object.keys(experience.priceDetails).length != 0 && <Accordion analyticsCategory={getAnalyticsCategory()} header={'Price Breakup'} body={experience.priceDetails} style={{width: '160px', background: 'transparent', display: 'none'}} />}
            </div>
          </div>

          <div className={styles.middleContainer}>
            {experience.immersions && <div className={styles.immersionsWrap}>
              <div className={styles.immersionsInnerWrap}>
                <h3>Get Immersed In...</h3>

                {getImmersionsList(experience.immersions).map((immersionList, ind) => {
                  return(
                    <div className={styles.immersionsContainer} key={ind}>
                      {immersionList.map((immersion, i) => {
                       return(<div className={`${flippedCards.includes(ind.toString() + i.toString()) ? styles.flippedImmersion : ''} ${styles.immersion}`} onClick={e => flipCard(e, ind.toString() + i.toString(), immersion.title)}>
                          <div className={styles.card}>
                            <div className={styles.flipCardFront}>
                              <img src={immersion.image} />
                              <label className={styles.immersionLabel}>{immersion.title}</label>
                              <i><Rotate /></i>
                            </div>
                            <div className={styles.flipCardBack}>
                              <label>{immersion.title}</label>
                              <p>{parse(immersion.description)}</p>
                            </div>
                          </div>
                        </div>)
                      })}
                    </div>
                  )
                })}
                
              </div>
            </div>}

            <div>
              <h3 className={styles.creatorHeading}>Meet the Creator</h3>
              <div style={{background: experience.brand.bgColor}} className={styles.creatorInfo} onClick={e => routeChange(e, `/brands/${Helper.generateURL(experience.brand.name)}`)}>
                <i className={styles.brandIcon}><img style={{width: '100%', borderRadius: experience.brand.icon.borderRadius || 0}} src={experience.brand.icon.img} /></i>
                <div className={styles.brandInfo}>
                  <label className={styles.brandName}>Creator - {experience.brand.name}</label>
                  <label className={styles.totalExperiences}>{experience.otherExperiences.length + 1} {experience.otherExperiences.length + 1 > 1 ? 'Experiences' : 'Experience'} Till Now</label>
                </div>
                <i className={styles.rightIcon}><RightArrow /></i>
              </div>
            </div>

            <div className={styles.additionalInfo}>
              <h3 className={styles.additionalInfoHeading}>Experience Overview</h3>
              <label className={styles.locationInfo}><b>Location :</b> {`${experience.locations[0].city}, ${experience.locations[0].state}, ${experience.locations[0].country}`}</label>
              <label className={styles.duration}><b>Duration :</b> {experience.duration}</label>
              <label className={styles.groupSize}><b>Group Size :</b> {`${experience.groupSize.min} - ${experience.groupSize.max} Persons`}</label>
              {experience.recurrences.length > 0 ? <label className={styles.dates}><b>Dates :</b> {`${Helper.formatDate(new Date(experience.recurrences[0].dates.start), 'short', false)} to ${Helper.formatDate(new Date(experience.recurrences[0].dates.end), 'short', false)}`}</label> : ''}
              <span className={styles.unitPrice}><b>Price :</b> {experience.unitPrice.toLocaleString()} /- per person</span>
            </div>

            {experience.stay && experience.stay.name && <div className={styles.experienceStay}>
              <ul className={styles.stayWrap}>
                <li className={styles.stayName} onClick={e => routeChange(e, location.pathname + '/stay')}>
                  <h3>Stay</h3>
                  <p>{experience.stay.name}</p>
                  <i><RightArrow /></i>
                </li>
                {experience.stay.images.map((image) => {
                  return(<li><img src={image} /></li>);
                })}
              </ul>
            </div>}

            {experience.food && <div className={styles.foodOptions}>
              <ul className={styles.foodWrap}>
                <li className={styles.foodName}>
                  <h3>{experience.food.title}</h3>
                  <p>{experience.food.label}</p>
                </li>
                {experience.food.images.map((image) => {
                  return(<li><img src={image} /></li>);
                })}
              </ul>
            </div>}

            <div className={styles.characteristics}>
              <Accordion analyticsCategory={getAnalyticsCategory()} header={'Includes'} body={experience.characteristics.inclusion.data} topContent={experience.characteristics.inclusion.highlight} style={{width: 'calc(50% - 55px)', background: '#fff', border: '3px solid var(--Canary-Yellow, #FFEA00)', padding: '20px', float: 'left'}} />
              <Accordion analyticsCategory={getAnalyticsCategory()} header={'Excludes'} body={experience.characteristics.exclusion.data} topContent={experience.characteristics.exclusion.highlight} style={{width: 'calc(50% - 55px)', background: '#fff', border: '3px solid var(--Canary-Yellow, #FFEA00)', padding: '20px', float: 'right'}} />
            </div>

            <div className={styles.itineraryAndTransport} ref={itineraryAndTransportRef}>
              <Accordion 
                analyticsCategory={getAnalyticsCategory()}
                header={'Highlights of Trip'}
                body={experience.itinerary.brief.data}
                topContent={experience.itinerary.brief.highlight}
                cta={{text: 'Detailed Itinerary', to: location.pathname + '/itinerary'}}
                openStyle={{width: '50%', background: '#fff', border: '2px solid var(--Canary-Yellow, #FFEA00)', padding: '20px', float: 'left'}}
                closedStyle={{width: '50%', background: '#FFEA00', border: '2px solid var(--Canary-Yellow, #FFEA00)', padding: '20px', float: 'left'}} />
              <div className={styles.transport} style={{height: transportHeight - 26}}>
                <h3>Transport{experience.transport.self ? ' (Self)' : ''}</h3>
                {Object.keys(experience.transport).filter(item => item !== 'self').map((key, i) => 
                  <p key={i}><b>{key}</b> - {experience.transport[key]}</p>
                )}
              </div>
            </div>

            <div className={styles.termsAndDetailedItinerary}>
              <div className={styles.terms} onClick={e => routeChange(e, location.pathname + '/terms')}>
                <h3>Terms</h3>
                <p>Explore terms and Conditions important for booking.</p>
                <i><RightArrow /></i>
              </div>
              <div className={styles.detailedItinerary} onClick={e => routeChange(e, location.pathname + '/itinerary')}>
                <h3>Detailed Itinerary</h3>
                <p>Explore our comprehensive detailed itinerary for a step-by-step guide to your upcoming adventure, including activities, accommodations, and highlights along the way</p>
                <i><RightArrow /></i>
              </div>
            </div>

            <div className={styles.instructions} onClick={e => routeChange(e, location.pathname + '/instruction')}>
              <h2>Additional Instructions</h2>
              <p>Prepare in advance by arranging transportation, double-checking hotel reservations, and booking guided tours for a comprehensive travel experience.</p>
              <i><RightArrow /></i>
            </div>

            <div className={styles.faqsWrap} onClick={e => routeChange(e, location.pathname + '/faqs')}>
              <h2>FAQs’</h2>
              <p>Browse our FAQs for answers to common travel questions and helpful tips for a smooth and enjoyable journey!</p>
              <i><RightArrow /></i>
            </div>
          </div>

          <div className={styles.bottomContainer}>
            {experience.otherExperiences && experience.otherExperiences.length > 0 ? <div className={styles.otherExperiencesContainer}><Experiences analyticsCategory={getAnalyticsCategory()} experiences={experience.otherExperiences} brand={experience.brand} setData={setData} experienceStyle={{background: '#F2F2F7'}} bgColor={experience.bgColor} heading={{color: '#1C1C1E', fontSize: '24px', text: 'Other Experiences by This Brand'}} scrollLineColor={'#1C1C1E'} scrollBarColor={'#FFEA00'} removeBGColor={true} containerId={'otherExperiencesContainer'} /></div>: ''}
            {similarExperience && similarExperience.length > 0 ? <div className={styles.similarExperiencesContainer}><Experiences analyticsCategory={getAnalyticsCategory()} experiences={similarExperience} brand={experience.brand} setData={setData} removeBGColor={true} experienceStyle={{background: '#F2F2F7'}} heading={{color: '#1C1C1E', fontSize: '24px', text: 'Other Similar Experiences'}} scrollLineColor={'#1C1C1E'} scrollBarColor={'#FFEA00'} containerId={'similarExperiencesContainer'} /></div>: ''}
          </div>          

          <ContactCreator analyticsCategory={getAnalyticsCategory()} hideCommentsBtn={true} open={openContactCreator} brand={Object.keys(experienceToContact).length != 0 ? experienceToContact.brand : (experience.brand ? experience.brand : {})} experience={Object.keys(experienceToContact).length != 0 ? experienceToContact : experience} storage={contactCreatorStorage} setData={setData} />

          {variationNumber < 3 && <div>
              <div className={styles.commentsButton} onClick={(e) => { e.stopPropagation(); setData(experience, 'contactCreatorLeads', true); trackAnalytics('Click_Comment', 'query');}}>
                <h2>Get Help</h2>
              </div>

              <div className={styles.bookExperienceFixed} onClick={e => routeChange(e, location.pathname + '/book')}>
                <h2>Book Experience</h2>
              </div>
          </div>}

          {variationNumber >= 3 && variationNumber < 6 && <a className={styles.callUsBtn} target="_blank" href="tel:9972136020">Call us at 9972136020</a>}

          {variationNumber > 5 && <a className={styles.whatsappBtn} target="_blank" href={`https://wa.me/+919972136020/?text=Hi, I want to know more about ${window.location.href}`}>Message us to know more</a>}
        </div>
       : ''}

      { !showLoader && Object.keys(experience).length != 0 && experience.shortExperience === true ?
        <div className={styles.topContainerParent}>
          <span className={styles.xphiveLogo}><img src='/xphive-logo-text.png' /></span>
          <Back analyticsCategory={getAnalyticsCategory()} style={{position: 'relative', left: '15px', top: '-48px', width: '28px'}} />
          <div className={styles.shareAndFavourite}>
            <Share analyticsCategory={getAnalyticsCategory()} hideCircle={true} blackIcon={true} style={{position: 'absolute', right: '100px', top: '50px'}}/>
            <Favourite analyticsCategory={getAnalyticsCategory()} hideCircle={true} id={experience._id} storage={'experience'} style={{ position: 'absolute', top: '50px', left: 'unset', bottom: 'unset', right: '45px'}} />
          </div>

          <div className={styles.shortExperienceContainer}>
            <div>
              {experience.icon && <img className={styles.icon} src={experience.icon} />}
              <div className={styles.titleAndLocationWrap}>
                <h3>{experience.title}</h3>
                <p>{`${experience.locations[0].city}, ${experience.locations[0].state} (${experience.locations[0].tag})`}</p>
              </div>

              <div className={styles.detailsWrap}>
                <div className={styles.imgWrap} style={{width: ((width - 60) * 0.60) - 20}}>
                  <img src={experience.bannerImages[0]} style={{width: ((width - 60) * 0.60) - 20}} />
                  <label>₹ {experience.unitPrice} /- {experience.characteristics.inclusion.highlight ? '(' + experience.characteristics.inclusion.highlight + ')' : ''}</label>
                </div>
                <div className={styles.basicDetails}>
                  {experience.vibes && experience.vibes.length > 0 && <span className={styles.vibeName}><b>Vibe:</b> {experience.vibes[0].tags.join(', ')}</span>}
                  <p className={styles.experienceDesc}>{experience.description}</p>
                  {experience.recurrences.length > 0 ? <label className={styles.time}><b>Time:</b> {`${Helper.formatDate(new Date(experience.recurrences[0].dates.start), 'short', false)} ${new Date(experience.recurrences[0].dates.start).toLocaleTimeString('en-US')} - ${new Date(experience.recurrences[0].dates.end).toLocaleTimeString('en-US')}`}</label> : ''}
                  
                  <p className={styles.curatedBy}>Curated by<br/><label>{experience.brand.name}</label></p>
                </div>
              </div>
            </div>
          </div>
          <BookExperienceForm experience={experience} />

        </div>
      : ''}
    </div>
  );
};

export default ExperienceDetails;
