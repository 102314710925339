import styles from "./Accordion.module.css";
import AccordionComp from "react-accordion-comp";
import "react-accordion-comp/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { DownArrow, UpArrow, RightBigArrow, } from '../icons';
import React, { useState,  } from 'react'
import { Helper, } from "../components";


const Accordion = (props) => {
	const navigate = useNavigate();
	const [isActive, setIsActive] = useState(false);
  const style = props.style ? props.style : (isActive ? props.openStyle : props.closedStyle);

	return (
		<div className={styles.accordion} style={{ ...style }}>
			{props.topContent ? 
				<h5>{props.header}</h5> :
				<h5 onClick={() => {Helper.trackAnalytics(props.analyticsCategory, `Click_${Helper.titleCase(props.header.split(' ').join('_'))}`, isActive ? 'Close' : 'Open'); setIsActive(!isActive); }}>{props.header}{!isActive && <i><DownArrow /></i>}</h5>
			}
			{props.topContent && !isActive && <p className={styles.topContent}>{props.topContent}<i onClick={() => {Helper.trackAnalytics(props.analyticsCategory, `Click_${Helper.titleCase(props.header.split(' ').join('_'))}`, isActive ? 'Close' : 'Open'); setIsActive(!isActive)}}><DownArrow /></i></p>}

      <AccordionComp isOpen={isActive}>
        <div>
            <ul style={{marginTop: props.topContent ? '25px' : '12px'}}>
              {!Array.isArray(props.body) && Object.keys(props.body).map((key, i) => 
                <li key={i}>{i + 1}. {key}: <b>{props.body[key]}</b></li>
              )}

              {Array.isArray(props.body) && props.body.map((data, i) =>  {
                return(typeof data === 'string' ? <li key={i}><b>{i + 1}.</b> {data}</li> : <li className={styles.list} key={i}><label><b>{data.title}:</b></label>{data.description}</li>)
              })}
              {props.cta && <button className={styles.ctaBtn} onClick={e => navigate(props.cta.to)}>{props.cta.text}<RightBigArrow fill={'black'} /></button>}
            </ul>
            <span onClick={() => {Helper.trackAnalytics(props.analyticsCategory, `Click_${Helper.titleCase(props.header.split(' ').join('_'))}`, isActive ? 'Close' : 'Open'); setIsActive(!isActive)}}>{isActive && <i><UpArrow /></i>}</span>
        </div>
      </AccordionComp>
		</div>
	);
}

export default Accordion;
