import {
  Routes,
  Route,
  Navigate,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import ExperienceDetails from "./pages/ExperienceDetails";
import CommunityDetails from "./pages/CommunityDetails";
import CreatorDetails from "./pages/CreatorDetails";
import BrandListings from "./pages/BrandListings";
import BrandDetails from "./pages/BrandDetails";
import BlogListings from "./pages/BlogListings";
import BlogDetails from "./pages/BlogDetails";
import Policies from "./pages/Policies";
import AboutUs from "./pages/AboutUs";
import LoginSignup from "./pages/LoginSignup";
import ResetPassword from "./pages/ResetPassword";
import VerifyEmail from "./pages/VerifyEmail";
import PageNotFound from "./pages/PageNotFound";
import XPVibes from "./pages/XPVibes";
import Portfolio from "./pages/Portfolio";
import CustomWishlist from "./pages/CustomWishlist";
import WishlistDetails from "./pages/WishlistDetails";
import EditPortfolio from "./pages/EditPortfolio";
import PortfolioSetting from "./pages/PortfolioSetting";
import TermsAndPrivacy from "./pages/TermsAndPrivacy";
import ExperienceTerms from "./pages/ExperienceTerms";
import BookExperience from "./pages/BookExperience";
import ExperienceItinerary from "./pages/ExperienceItinerary";
import ExperienceInstruction from "./pages/ExperienceInstruction";
import ExperienceStay from "./pages/ExperienceStay";
import ExperienceFaqs from "./pages/ExperienceFaqs";
import DestinationDetails from "./pages/DestinationDetails";
import PlanWithXPHive from "./pages/PlanWithXPHive";
import MyVibes from "./pages/MyVibes";
import Listings from "./pages/Listings";
import { LoginHelper, Helper, } from "./components";
import MetaHelper from "./MetaHelper"
import SummerBreeze from "./pages/SummerBreeze";
import { useEffect, useState } from "react";
import './config';
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
ReactGA.initialize(global.config.TRACKING_ID);
ReactPixel.init(global.config.PIXEL_TRACKING_ID);


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  Helper.setDeviceId();


  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }, [action, pathname]);

  useEffect(() => {
    const titleAndDesc = MetaHelper.getTitleAndDesc(pathname);
    const title = titleAndDesc.title;
    const metaDescription = titleAndDesc.description;

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
    ReactPixel.pageView();
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={ Helper.storage.getItem('xpvibeUniqueId') ? <MyVibes /> : <Home />} />
      <Route path="/experiences/:slug/terms" element={<ExperienceTerms />} />
      <Route path="/experiences/:slug/book" element={<BookExperience />} />
      <Route path="/experiences/:slug/itinerary" element={<ExperienceItinerary />} />
      <Route path="/experiences/:slug/instruction" element={<ExperienceInstruction />} />
      <Route path="/experiences/:slug/stay" element={<ExperienceStay />} />
      <Route path="/experiences/:slug/faqs" element={<ExperienceFaqs />} />
      <Route path="/experiences/:slug" element={<ExperienceDetails />} />
      <Route path="/communities/:slug" element={<CommunityDetails />} />
      <Route path="/destinations/:city" element={<DestinationDetails />} />
      <Route path="/brands/:brandName" element={<BrandDetails />} />
      <Route path="/travel-with-xphive" element={<PlanWithXPHive />} />
      <Route path="/blogs/:slug" element={<BlogDetails />} />
      <Route path="/privacy-policy" element={<TermsAndPrivacy policy='privacy' />} />
      <Route path="/cancellation-policy" element={<Policies policy='cancellation' />} />
      <Route path="/refund-policy" element={<Policies policy='refund' />} />
      <Route path="/terms-policy" element={<TermsAndPrivacy policy='terms' />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/custom-wishlist" element={ LoginHelper.isUserLoggedIn() ? <CustomWishlist /> : <Navigate to="/login-signup" />} />
      <Route path="/wishlists/:id" element={<WishlistDetails />} />
      <Route path="/login-signup" element={ LoginHelper.isUserLoggedIn() ? <Navigate to="/" /> : <LoginSignup />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/xpvibes" element={<XPVibes />} />
      <Route path="/myvibes" element={<MyVibes />} />
      <Route path="/portfolio/:handle/edit" element={<EditPortfolio />} />
      <Route path="/portfolio/:handle/setting" element={<PortfolioSetting />} />
      <Route path="/portfolio/:handle" element={<Portfolio />} />
      <Route path="/listings" element={<Listings />} />
      <Route path="/summer-breeze" element={<SummerBreeze />} />
    </Routes>
  );
}
export default App;
